import { Typography, Link, AppBar, Modal } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import colors from '../Assets/colors';
import { UnitedStatesContext } from '../Contexts/UnitedStatesContext';

function getModalStyle() {
  const top = 50 + Math.round(Math.random() * 20) - 10;
  const left = 50 + Math.round(Math.random() * 20) - 10;

  return {
    // top: `${top}%`,
    // left: `${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
    top: `25%`,
    left: `50%`,
    transform: `translate(25%, 50%)`,
    width: '50%',
    height: '47%'
  };
}

export default function Footer(){
    const [open, setOpen] = useState(false);
    const { resources }= useContext(UnitedStatesContext);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <AppBar
            position="static"
            style={{
                padding: '1.5rem',
                // width: '100%',
                marginTop: '2rem',
                backgroundColor: colors.medium_gray,
                top: 'auto',
                bottom: 0
            }}
            id="bottom"
        >
            <Typography>
                Tell us about other State-led Digital Equity initiatives by  <Link style={{color: 'white', textDecoration: 'underline', cursor: 'pointer'}} onClick={handleOpen}>filling out this form</Link>.
            </Typography>

             <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div 
                    style={getModalStyle()}
                >
                    <iframe
                        title='feedback-form' 
                        width='100%' 
                        height='100%'
                        frameborder='0' 
                        marginwidth='0' 
                        marginheight='0' 
                        allowfullscreen 
                        webkitallowfullscreen 
                        mozallowfullscreen 
                        msallowfullscreen
                        src={resources.length >= 1 && resources.filter(resource => resource.fields['Name'] === 'feedback_form').map(form => form.fields.Language)[0].trim()}
                    >
                    </iframe>
                </div>
            </Modal>
            
        </AppBar>
    )
}