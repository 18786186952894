import React, { useState } from 'react';
import { AppBar, Toolbar, Breadcrumbs, Button, Menu, MenuItem, Box } from '@material-ui/core'
import {
  BrowserRouter as Router,
  Route,
  NavLink
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles"; 
import { ThemeProvider } from '@material-ui/core/styles';
import colors from './Assets/colors';
import Home from './Pages/Home';
import US from './Pages/US';
import Methods from './Pages/Methods';
import Footer from './Components/Footer';
import {UnitedStatesProvider} from './Contexts/UnitedStatesContext';
import theme from './Contexts/CustomTheme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CssBaseline from '@material-ui/core/CssBaseline';
import microsoftLogo from './Assets/Microsoft-logo_rgb_c-gray.png';
import ndiaLogo from './Assets/Logo With URL@4x.png';
import nscLogo from './Assets/nsc-logo.png';

const breadcrumbNameMap = {
  '/scorecard': 'US',
  '/scorecard/by_state': 'Search by State',
  '/scorecard/by_indicator': 'Search by Indicator',
  '/methods': 'Methods'
};

const useStyles = makeStyles({
  root: {
    '& .nav-link': {
      color: colors.dark_gray,
      fontSize: "1.25rem",
      padding: ".5rem",
      display: 'inline-block',
      fontFamily: 'Poppins',
      textTransform: 'uppercase',
      '&:hover': {
       color: colors.NDIA_red,
       backgroundColor: 'transparent'
      }
    },
    '& .app-bar' : {
      backgroundColor: 'white',
      // padding: ".5rem .5rem .5rem 2rem",
      // marginBottom: "1.5rem",
      // alignItems: 'flex-end'
    },
    '& .content' : {
      // margin: '2rem'
    },
    '& .breadcrumbs' : {
      margin: '0rem 0rem 3.5rem 2rem'
    }
  }
})



function App() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <Router>
        <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={classes.root}>
        
          <AppBar 
            position="static"
            className='app-bar'
            id="top"
            >
            <Toolbar>              

              <Box display='flex' flexGrow={1} style={{alignItems: 'center'}}>
                <a target="_blank" rel="noreferrer" href="https://www.microsoft.com/en-us/corporate-responsibility/inclusive-economic-opportunity?rtc=1">
                  <img style={{width: '8rem', margin: "1rem"}} alt='microsoft logo' src={microsoftLogo}/>
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.digitalinclusion.org/">
                  <img style={{width: '5rem', margin: "1rem"}} alt='national digital inclusion alliance logo' src={ndiaLogo}/>
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.nationalskillscoalition.org/">
                  <img style={{width: '7rem', margin: "1rem"}} alt='national skills coalition logo' src={nscLogo}/>
                </a>
                
              </Box>

              <NavLink className="nav-link" exact to="/">Home</NavLink >

              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} className='nav-link'>
                Scorecard
                <ExpandMoreIcon/>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  <NavLink className='nav-link' to="/scorecard/by_state">Choose a State</NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <NavLink className='nav-link' to="/scorecard/by_indicator">Choose an Indicator</NavLink>
                </MenuItem>
              </Menu>

              <NavLink className="nav-link" to="/methods">Methods</NavLink >
            </Toolbar>
                
          </AppBar>

          {/* <Route>
            { ({ location }) => {
              const pathnames = location.pathname.split('/').filter((x) => x);

              return pathnames.length >= 1 ? (
                <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
                  <NavLink to="/">
                    Home
                  </NavLink>
                  {pathnames.map((value, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                    return last ? (
                      <Typography color="textPrimary" key={to}>
                        {breadcrumbNameMap[to] ? breadcrumbNameMap[to] : stateNames[pathnames[pathnames.length - 1]]}
                      </Typography>
                    ) : (
                      <NavLink color="inherit" to={to} key={to}>
                        {breadcrumbNameMap[to]}
                      </NavLink>
                    );
                  })}
                </Breadcrumbs>
              ) : null ;
            }}
          </Route> */}

          <UnitedStatesProvider>
            <div className="content">
              <Route exact path="/">
                <Home/>
              </Route>

              <Route path="/scorecard">
                <US/>
              </Route>
              
              <Route path="/methods">
                <Methods/>
              </Route>
            </div>
          <Footer/>

          </UnitedStatesProvider>

        </div>
        </ThemeProvider>
      </Router> 
  )
}

export default App;

