import React, {useState, useContext} from 'react';
import ReactMarkdown from 'react-markdown';
import { Container, Grid, List, Link, ListItem, ListItemText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import { UnitedStatesContext } from '../Contexts/UnitedStatesContext';
import Strong from '../Components/Strong'
import colors from '../Assets/colors';

const useStyles = makeStyles({
    root: {
        '& .section-header': {
            marginBottom: '1.5rem'
        }
    }
})
function Methods(){
    const {definitions, indicators, subindicators, resources} = useContext(UnitedStatesContext);
    const classes = useStyles();

    return (
        <Container maxWidth="md" style={{marginTop:"2rem"}} className={classes.root}>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            >
                <Grid item xs>
                    <Typography variant="h4" id='definitions' className='section-header'>Definitions</Typography>

                    {definitions.map(def => <ReactMarkdown key={`def_for_${def.fields.Definition}`} children={def.fields.Definition}></ReactMarkdown>)}

                    <Typography variant="h4" id='indicators' className='section-header'>Indicator Methodology</Typography>

                    {indicators.map(indicator => 
                    <div key={`method_for_${indicator.fields.Title}`}>
                        <Typography variant="h6">
                            <Strong>{indicator.fields.Title}</Strong>
                        </Typography>
                        <Typography variant="body1">
                            {<ReactMarkdown linkTarget="_blank" children={indicator.fields['Description']}></ReactMarkdown>}
                        </Typography>
                        <br/>
                          <Typography variant="body1">Sub-Indicators: </Typography>
                        <List>
                            {indicator.fields['Sub-Indicators'].sort((a,b) => subindicators.filter(subindicator => subindicator.id === a).map(subindicator => subindicator.fields.Order)[0] -  subindicators.filter(subindicator => subindicator.id === b).map(subindicator => subindicator.fields.Order)[0]).map(rec_id => 

                            <ListItem key={`list_item_${rec_id}`} alignItems="flex-start">

                                {subindicators.filter(subindicator => subindicator.id === rec_id).map(subindicator => 
                                <ListItemText
                                    key={`question_${subindicator.fields.Question}`}
                                    style={{marginRight: '1.5rem'}}
                                    primary= {<Typography variant="body2">{subindicator.fields.Question}</Typography>}
                                    secondary= {subindicator.fields.Specification}
                                />)}
                                
                            </ListItem>
                            )}
                        </List>
                    </div>
                    )}

                    <Typography variant="h4" id='scoring' className='section-header'>Scoring Methodology</Typography>

                    {resources.filter(resource => resource.fields['location_on_page'].includes('scoring')).map(resource => 
                        <div key={`resource_${resource.fields.name_external}`}>
                            <h5>{resource.fields.name_external}</h5>
                            <ReactMarkdown linkTarget="_blank" children={resource.fields.Language}></ReactMarkdown>
                        </div>
                    )}


                    <Typography variant="h4" id='labor' className='section-header'>Labor Statistics Methodology</Typography>

                    {resources.filter(resource => resource.fields['location_on_page'].includes('labor_stat')).map(resource => 
                        <div key={`resource_${resource.fields.name_external}`}>
                            <h5>{resource.fields.name_external}</h5>
                            {resource.fields['Language Long'].length > 1 ? 
                                <ReactMarkdown linkTarget="_blank" children={resource.fields['Language Long']}></ReactMarkdown> 
                                : 
                                <ReactMarkdown linkTarget="_blank" children={resource.fields['Language']}></ReactMarkdown> 
                            }
                        </div>
                    )}

                    <Typography variant="h4" id='resources' className='section-header'>More Resources</Typography>

                    {resources.filter(resource => resource.fields['location_on_page'].includes('resource')).map(resource => 
                        <div key={`resource_${resource.fields.name_external}`}>
                            <h5>{resource.fields.name_external}</h5>
                            <ReactMarkdown linkTarget="_blank" children={resource.fields.Language}></ReactMarkdown>
                        </div>
                    )}
                </Grid>
                <Grid item xs={3}>
                    <List component="nav" aria-label="page contents" style={{position: 'fixed', borderLeftColor: colors.medium_gray, borderLeftStyle: 'solid', marginLeft: '1.5rem'}}>
                        <ListItem button component="a" href="#definitions">
                            <ListItemText primary="Definitions"></ListItemText>
                        </ListItem>
                        <ListItem button component="a" href="#indicators">
                            <ListItemText primary="Indicators"></ListItemText>
                        </ListItem>
                        <ListItem button component="a" href="#scoring">
                            <ListItemText primary="Scoring"></ListItemText>
                        </ListItem>
                        <ListItem button component="a" href="#labor">
                            <ListItemText primary="Labor Statistics"></ListItemText>
                        </ListItem>
                        <ListItem button component="a" href="#resources">
                            <ListItemText primary="More Resources"></ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
            
        </Container>
    )
}

export default Methods; 