import React, {useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import statesTitlecase from '../Assets/statesTitlecase';
import USMapImage from './USMapImage';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function UsMap(props){
    const classes = useStyles();
    const [state, setState] = React.useState('');

    function handleChange(newState){
        setState(newState);
        props.onSelect(newState);
    };
    return (
    
        <div></div>
      
    )
}