import React, { useContext } from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Accordion, AccordionDetails, AccordionSummary, Typography, Link } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UnitedStatesContext } from '../Contexts/UnitedStatesContext';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import colors from '../Assets/colors';

// import { LoremIpsum } from 'react-lorem-ipsum';


export default function ScoreCard(props){
    const {subindicators, indicators} = useContext(UnitedStatesContext);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    
    return (
        <div>

        {indicators.map(indicator => 
                        <Accordion key={indicator.id} expanded={expanded === indicator.id} onChange={handleChange(`${indicator.id}`)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={indicator.fields.Title}
                                id={indicator.fields.Title}
                                >
                                <Typography varaint="h4">{indicator.fields.Title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <List style={{width: '100%'}}>
                                    {indicator.fields['Sub-Indicators'].sort((a,b) => subindicators.filter(subindicator => subindicator.id === a).map(subindicator => subindicator.fields.Order)[0] -  subindicators.filter(subindicator => subindicator.id === b).map(subindicator => subindicator.fields.Order)[0]).map(rec_id => 

                                    <ListItem alignItems="flex-start" >
                                        {/* {console.log(typeof rec_id)} */}

                                        {subindicators.filter(subindicator => subindicator.id === rec_id).map(subindicator => 
                                        <ListItemText
                                            key={`scorecard_subindicator_${subindicator.fields.id}`}
                                            style={{marginRight: '1.5rem'}}
                                            primary= {<Typography variant="body2">{subindicator.fields.Question}</Typography>}
                                        />)}

                                        {props.stateData ? ( props.stateData.fields["Sub-Indicators Met"] ? (props.stateData.fields["Sub-Indicators Met"].includes(rec_id) ?
                                        <ListItemIcon>
                                            <CheckCircleIcon style={{fill: colors.green}}/>
                                        </ListItemIcon>
                                        : <ListItemIcon>
                                            <CancelIcon style={{fill: colors.NDIA_red}}/>
                                        </ListItemIcon>) 
                                        : <ListItemIcon>
                                            <CancelIcon style={{fill: colors.NDIA_red}}/>
                                        </ListItemIcon>) : null}

                                        
                                    </ListItem>
                                    )}
                                    {props.stateData && 
                                        <div style={{fontSize: ".75rem", paddingLeft: '1rem', paddingTop: '1rem'}}>
                                            <Link href={`#${indicator.fields.Name}_full`}>
                                                <ArrowDownwardIcon style={{fontSize: ".75rem"}}/>
                                                Read more
                                            </Link>
                                        </div>
                                    }
                                </List>
                            
                            </AccordionDetails>
                        </Accordion>
                    )}

            
            
        </div>
    )
}

