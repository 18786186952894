import { Typography } from '@material-ui/core';
import colors from '../Assets/colors';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  useParams
} from "react-router-dom";

function ScorecardBar(props){
    return (
        <div
            style={{
                backgroundColor: colors.NDIA_teal,
                width: '100%',
                marginBottom: '2rem',
                padding: '1.5rem',
                color: 'white'
            }}
        >
            {props.viewName === 'state' ?
                <Typography>
                    Select a state from the map or the drop down menu below, or switch to <NavLink style={{color: 'white', textDecoration: 'underline'}} to={`/scorecard/by_indicator`} >indicator view</NavLink>. 
                </Typography>
                :
                <Typography>
                    Select an indicator from the drop down menu below, or switch to <NavLink style={{color: 'white', textDecoration: 'underline'}} to={`/scorecard/by_state`} >state-by-state view</NavLink>. 
                </Typography>
            }
        </div>
    )
}

export default ScorecardBar