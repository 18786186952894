import React, { useState, useEffect, useContext } from 'react';
import colors from '../Assets/colors';
import { UnitedStatesContext } from '../Contexts/UnitedStatesContext';
import { Popover, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {possibleScores, colorArray} from '../Assets/possibleScores';

import * as d3 from 'd3';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    popover: {
        pointerEvents: 'none'
    },
    paper: {
        padding: theme.spacing(1),
        maxWidth: '25%'
    },
}));

function switchColor(colorArray, score){
    let m = colorArray.length;

    let color = ''
    for (var i = 0; i <= m; i++){
        if( i <= score && score < i +1){
            color = colorArray[i]
        }
    }
    return color;
}

const StatePath = React.memo(function StatePath(props){
    const classes = useStyles();
    const { stateData, subindicators } = useContext(UnitedStatesContext);
    const [active, setActive] = useState(false);
    const [popoverState, setPopoverState] = useState();
    const [anchorEl, setAnchorEl] = useState(null);

    const indicator_points = subindicators.map(subindicator => subindicator.fields['Points'])

    const n = possibleScores(indicator_points).length;
    
    const thisColorArray = colorArray(n);

    const score = stateData.filter(state => state.fields["State Abbreviation "] === _.upperCase(props.id)).map(state => state.fields['New Score'])[0]

    useEffect(() => {
        if(props.activeState !== null){
            if(props.activeState === _.upperCase(props.id)){
                setActive(true)
            } else {
                setActive(false)
            }
        }
    }, [props.activeState, props.id])

    useEffect(() => {
        if(props.filterRange.length >= 1 ){
            let inRange = _.round(score, 1) >= props.filterRange[0] && _.round(score, 1) < props.filterRange[1]

            if(active && !inRange){
                setActive(false)
            }
            else if(!active && inRange){
                setActive(true)
            }
        }
    }, [props.filterRange, active, score])



    function stateStyle(){

        return {
            fill: active ? colors.NDIA_red : switchColor(thisColorArray, _.round(score, 3)),
            stroke: 'white',
            strokeWidth: '2px'
        } 
    }

    function threshold(score){
        return d3.scaleThreshold().domain([.26, .52]).range([colors.firetruck_red, colors.naples_yellow, colors.apple_green])(score)
    } 

    // Blue scale : 
    
    // d3.scaleLinear().domain([0, 0.5, 1]).range(['#D1495B', colors.light_yellow,  colors.green]).interpolate(d3.interpolateRgb.gamma(2))
    // d3.interpolateYlGn
    // d3.interpolateLab('rgb(241, 238, 246)', 'rgb(3, 78, 123)')(score)
    // darken(d3.interpolateLab('rgb(241, 238, 246)', 'rgb(3, 78, 123)')(score))

    function darken(color, k = 1) {
        const {l, c, h} = d3.lch(color);
        return d3.lch(l - 18 * k, c, h);
    }

     const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setPopoverState(event.target.id);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setPopoverState('');
    };


    return (
        <g>
            <path
                strokeLinejoin={props.strokeLinejoin}
                strokeWidth={props.strokeWidth}
                d={props.d}
                className='state' 
                onClick={(event) => props.onStateClick(_.upperCase(props.id))}
                id={_.upperCase(props.id)}
                style={stateStyle()}
                cursor={props.cursor}
                display={props.display}
                aria-owns={props.open ? `mouse-over-${_.upperCase(props.id)}-popover` : undefined}
                aria-haspopup='true'
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            ></path>
            <Popover 
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                id={`mouse-over-${_.upperCase(props.id)}-popover`}
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={popoverState === _.upperCase(props.id) ? true : false }
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
                >

                <Typography variant="h5">
                    {props.name}
                </Typography>
                <Typography variant="body1">
                    {stateData.filter(state => state.fields["State Abbreviation "] === _.upperCase(props.id)).map(state => _.round(state.fields["New Score"], 1))[0]} out of {n-1} possible points. 
                </Typography>
            </Popover>
        </g>
    )
})

export default StatePath; 