import React, {useState, useEffect, useContext} from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Strong from '../Components/Strong';
import stateNames from '../Assets/stateNames';
import UsMap from '../Components/UsMap';
import USMapImage from '../Components/USMapImage';
import colors from '../Assets/colors';
import NumberFormat from 'react-number-format';
import ScoreCard from '../Components/ScoreCard';
import FullReport from '../Components/FullReport';
import { UnitedStatesContext } from '../Contexts/UnitedStatesContext';
import { useParams } from 'react-router-dom';
import * as d3 from 'd3';
import MyPaper from '../Components/MyPaper';
import OpportunityGapFootnotes from '../Components/OpportunityGapFootnotes';
import ScorecardBar from '../Components/ScorecardBar';
import statesTitlecase from '../Assets/statesTitlecase';
import { useLocation } from "react-router-dom";
import ReactMarkdown from 'react-markdown';
import GenerateLabel from '../Components/GenerateLabel';
import {possibleScores, colorArray} from '../Assets/possibleScores';

const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    formControl: {
        marginLeft: '3rem',
        minWidth: '50%',
    },
    gridContent: {
        margin: '1.5rem',
        padding: '1.5rem'
    },
    selectEmpty: {
        marginTop: '1.5rem',
    },
}));


export default function ByState(props){
    const { stateParam, pageAnchor } = useParams();
    const { stateData, subindicators, resources }= useContext(UnitedStatesContext);
    const classes = useStyles();
    const [stateName, setStateName] = useState(' ');
    const [activeState, setActiveState] = useState([]);
    const [filterRange, setFilterRange] = useState([]);
    let urlLocation = useLocation();
    
    useEffect(() => {
        if(stateParam){
            setStateName(stateNames[stateParam]);
        }        

    }, [stateParam, pageAnchor])

    useEffect(() => {
        const el = document.querySelector(`#${pageAnchor}`)

        if(el && activeState[0].fields['State Abbreviation '] === stateParam){
            el.scrollIntoView({
                behavior: 'smooth'
            })
        }
    }, [stateParam, pageAnchor, activeState])

    useEffect(() => {
        if(!stateData) {
            return 
        } else {
            if(stateParam){
                setActiveState(stateData.filter(item => item.fields['State Abbreviation '] === stateParam));
            }
        }
    }, [stateData])

    function OpportunityGap(){

        return(
            <React.Fragment>
            {activeState.length >=1 &&
                <div>
                    {resources.length >= 1 && <ReactMarkdown
                        children={
                            resources.filter(item => item.fields['Name'] === 'opportunity_gap').map(item => item.fields['Language'])[0]
                            .replaceAll('[region]', `${activeState[0].fields["State Name"]}`)
                            .replace('[totalUnemployed]', `${activeState[0].fields.total_unemployed.toLocaleString('en')}`)
                            .replace('[unemployedWithoutDS]', `${_.round(activeState[0].fields.unemployed_without_ds).toLocaleString('en')}`)
                            .replace('[jobsWithDS]', `${activeState[0].fields.jobs_with_ds.toLocaleString('en')}`)
                            .replace('[jobsPercent]', `${activeState[0].fields.percent_with_ds.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1})}`)
                            .replace('[percentUnemployedServed]', `${activeState[0].fields['percent_unemployed_served'].toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1})}`)
                        }
                    >
                    </ReactMarkdown>
                    }
                </div>
            }
            </React.Fragment>
        )
    }

    let indicator_points = subindicators.map(subindicator => subindicator.fields['Points'])

    let allScores = possibleScores(indicator_points);

    let n = allScores.length;
    
    let thisColorArray = colorArray(n);

    return (
        <div className={classes.root}>
            <Grid 
                container
                direction="row"
                justify="space-evenly"
                alignItems="flex-start"
                // spacing={4}
                style={{minHeight: '100vh'}}
                >
                <Grid item sm={12} md={5}>
                    <Grid item>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="state-select-label">Select a State</InputLabel>
                            <Select
                                labelId="state-select-label"
                                id="state-select"
                                value={stateName}
                                onChange={(event) => {
                                    setStateName(event.target.value);
                                    setActiveState(stateData.filter(item => item.fields['State Name'] === event.target.value));

                                    const abbrev = statesTitlecase.filter(state => state.name.trim() === event.target.value).map(state => state.abbreviation)[0];

                                    const baseURL = urlLocation.pathname.split('/').length > 3 ? urlLocation.pathname.split('/')[0] + '/' + urlLocation.pathname.split('/')[1] + '/' + urlLocation.pathname.split('/')[2] : urlLocation.pathname

                                    window.history.replaceState(`${baseURL}/${abbrev}`, '', `${baseURL}/${abbrev}`);
                                }}
                            >
                            {stateData.map(state => 
                                <MenuItem key={`menu_for_${state.fields['State Name']}`} value={state.fields['State Name']}>
                                    {state.fields['State Name']}
                                </MenuItem>)
                            }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <div className={classes.gridContent}>
                            {/* <Typography variant="h3">Score Card</Typography> */}
                            {activeState.length >=1 && <Card style={{padding: '16px', marginBottom: '16px'}}>
                                <OpportunityGap />
                            </Card>}
                            <ScoreCard
                                stateData={activeState.length >=1 ? activeState[0]: null}
                            />
                        </div>
                    </Grid>
                </Grid>
                {/* <Grid
                    item
                    sm={12}
                    md={5}
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                    spacing={2}
                    > */}

                    
                    
                    {/* style={{minWidth: '100%'}} */}
                    
                    <Grid item sm={12} md={5} >
                        <div 
                            style={{
                                alignItems: 'flex-end',
                                alignContent: 'flex-end',
                                justifyItems: 'left',
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                flexWrap: 'wrap'
                            }}
                        >
                            <Typography variant="body2" style={{marginBottom: '.25rem'}}>Select a Score Range to Filter the Map</Typography >
                            <GenerateLabel 
                                n={n} 
                                colorArray={thisColorArray} 
                                scores={allScores} 
                                filterRange={filterRange}
                                onChangeFilter={(newRange) => {
                                    setFilterRange(newRange);
                                }}
                            />
                        </div>
                        <USMapImage
                            className={classes.gridContent}
                            value={activeState.length >=1 ? activeState[0].fields['State Abbreviation '] : null}
                            filterRange={filterRange}
                            onChangeState={(newState) => {
                                setStateName(stateNames[newState]);
                                setFilterRange([]);
                                setActiveState(stateData.filter(item => item.fields['State Abbreviation '] === newState));
                            }}
                        />
                        
                    </Grid>
                    {/* <Grid sm={12} md={1}>
                        
                    </Grid> */}
                {/* </Grid> */}
                
            </Grid>
            <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
                >

                {/* <Grid item sm={12} md={5}>
                    <OpportunityGap className={classes.gridContent}/>
                </Grid> */}

                {activeState.length >=1 && 
                    <Grid item sm={12} md={6}>
                        {/* <div className={classes.gridContent}> */}
                            {/* <Typography variant="h3">{activeState[0].fields["State Name"]}</Typography> */}

                        <MyPaper variant="outlined" >
                            <br/>
                            <FullReport
                                stateData={activeState.length >=1 ? activeState[0]: null}
                            />
                            <br/>
                            <br/>
                            <Typography variant='body1'>
                                <Strong>
                                    {activeState[0].fields["State Name"]} was last updated {activeState[0].fields["Last Modified"]}
                                </Strong>
                            </Typography>
                        </MyPaper>
                            
                        {/* </div> */}
                    </Grid>
                }
                <Grid item sm={12} md={6}>
                    <OpportunityGapFootnotes/>
                </Grid>

            </Grid>
        </div>
    );
}