import React from 'react';
import { Paper } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';


const MyPaper = styled(Paper)({
  margin: '1.5rem',
  padding: '1.5rem'
})

export default MyPaper;