import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import colors from '../Assets/colors';

const theme = createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: colors.NDIA_teal,
          textDecoration: 'none',
          '&:hover': {
            color: colors.NDIA_red,
            textDecoration: 'none'
          }
        },
      },
    },
  },
  palette: {
    primary: {
        main: colors.NDIA_teal
    }, 
    secondary: {
        main: colors.NDIA_red
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
    //   'Montserrat',
    ].join(','),
    
  }
});

export default theme; 