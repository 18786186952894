import * as d3 from 'd3';
import colors from '../Assets/colors';
const _ = require('lodash');
 
function subsetSums(arr, n) {
    let array_of_sums = [];
    // There are totoal 2^n subsets
    let total = 1 << n;

    // Consider all numbers from 0 to 2^n - 1
    for (let i = 0; i < total; i++) {
        let sum = 0;

        // Consider binary representation of
        // current i to decide which elements
        // to pick.
        for (let j = 0; j < n; j++) if ((i & (1 << j)) !== 0) sum += arr[j];

        // Print sum of picked elements.
        array_of_sums.push(sum);
    }
    return array_of_sums;
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function possibleScores(indicator_points) {
    const possibleScoresRaw = subsetSums(
            indicator_points,
            indicator_points.length
        ).map((number) => _.round(number, 0));
    return possibleScoresRaw
            .filter(onlyUnique)
            .sort((a, b) => a - b);
} 

function colorArray(n) {
    return d3.quantize(
        d3.interpolateRgb(colors.NDIA_light_teal, colors.NDIA_dark_teal),
        n
    );
}

export {possibleScores, colorArray};