import React, {useState, useEffect, useContext} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  useParams
} from "react-router-dom";
import NatOpportunityGap from './NatOpportunityGap';
import ByState from './ByState';
import ByIndicator from './ByIndicator';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AppBar } from '@material-ui/core';
import ScorecardBar from '../Components/ScorecardBar';


// function PageGrid(props){

//     return (
//             <Grid
//                 container
//                 direction="row"
//                 justify="space-evenly"
//                 alignItems="flex-start"
//             >
//                 <Grid item xs={6}>
//                     <NatOpportunityGap/>
//                 </Grid>
//                 <Grid item xs={3}>
//                     <Typography variant="body1">
//                         <NavLink 
//                             to={`${props.routeMatch.url}/by_state`}
//                             className="btn btn-outline-dark btn-lg">
//                             Search by State
//                         </NavLink>
//                     </Typography>
//                     <br/>
                    

//                     <Typography variant="body1">
//                         <NavLink 
//                             to={`${props.routeMatch.url}/by_indicator`}
//                             className="btn btn-outline-dark btn-lg">
//                             Search by Indicator 
//                         </NavLink>
//                     </Typography>
                    
//                 </Grid>
//             </Grid>
//     )
// }

function US(){
    let match = useRouteMatch();

    return (
        <div>
            <Switch>
                <Route path={`${match.path}/by_state/:stateParam?/:pageAnchor?`}>
                    <ScorecardBar viewName='state'/>
                    <ByState/>
                </Route>
                <Route path={`${match.path}/by_indicator`}>
                    <ScorecardBar viewName='indicator'/>
                    <ByIndicator/>
                </Route>
                {/* <Route path={match.path}>
                    <PageGrid
                        routeMatch={match}
                    /> 
                </Route> */}
            </Switch>
        </div>
    )
}

export default US;