const colors = {
    // red : "#F34F1C",
    red: "#AC3931",
    // green : "#7FBC00",
    green: "#98CE00",
    orange : "#FFBA01",
    yellow_opaque : "rgb(255, 154, 2)",
    yellow : "rgba(255, 154, 2, .5)",
    medium_gray : "#747474",
    dark_gray : "#2D2D2D",
    light_gray : "#AAAAAA", 
    light_blue : "#d8f0fe",
    dark_blue : "rgb(3, 78, 123)",
    pink: '#E5D0CC',
    light_yellow: "#FBF8B0",
    rusty_red: "#D1495B",
    firetruck_red: "#F6511D",
    apple_green: "#7FB800",
    naples_yellow: "#FFE66D",
    NDIA_teal: "#0b9599",
    NDIA_dark_teal: "#00393C",
    NDIA_light_teal: "#B0DEE0",
    NDIA_red: "#B81124"
}

export default colors; 