import React, { useState, useContext } from 'react';
import { Grid, Popover, Paper, Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import NatOpportunityGap from './NatOpportunityGap';
import OpportunityGapFootnotes from '../Components/OpportunityGapFootnotes';
import { UnitedStatesContext } from '../Contexts/UnitedStatesContext';
import colors from '../Assets/colors';
import InfoIcon from '@material-ui/icons/Info';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  useParams
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';


import US from './US'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '25%'
  },
}));

const Home = React.memo(props => {
    const classes = useStyles();
    const { resources } = useContext(UnitedStatesContext);
    const [anchorEl, setAnchorEl] = useState(null);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if(resources){
        return(
            <div style={{marginTop: '2rem'}}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item sm={12} md={6}>
                    <Typography variant="h2">
                        {resources && resources.filter(item => item.fields['Name'] === 'home_title').map(item => item.fields['Language'])[0]}
                        <sup>
                            <InfoIcon
                                aria-owns={open ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                            />
                        </sup>
                    </Typography>
                    <Popover 
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        id="mouse-over-popover"
                        className={classes.popover}
                        classes={{
                        paper: classes.paper,
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                        >
                        <Typography variant="caption">
                            {resources && resources.filter(item => item.fields.Name === 'digital_inclusion').map(item => item.fields.Tooltip)}
                            {resources && resources.filter(item => item.fields.Name === 'digital_equity').map(item => item.fields.Tooltip)}
                        </Typography>
                    </Popover>
                        <Typography style={{marginTop: '.5rem'}} variant="body1">
                            {resources && resources.filter(item => item.fields['Name'] === 'home_intro').map(item => item.fields['Language'])[0]}
                        </Typography>
                    </Grid>
                    <Grid 
                        item 
                        sm={12} 
                        md={6}
                        container
                        direction="row"
                        justify="space-between"
                        style={{marginTop:"2rem"}}
                        >
                        <Grid item md={6}>
                            <NatOpportunityGap/>
                        </Grid>

                        <Grid item md={4}>
                        <Paper
                            style={{
                                backgroundColor: colors.NDIA_light_teal,
                                padding: '1rem'
                            }}
                        >
                            <Typography variant="h5"
                                style={{
                                paddingBottom: '1rem'
                            }}
                            >
                                Explore the Scorecard 
                            </Typography>

                            <Typography variant="body1">
                                <NavLink 
                                    to={`/scorecard/by_state`}
                                    // className="btn btn-outline-dark btn-lg"
                                    >
                                    <Button variant="contained" color="secondary" size="large">
                                        Choose a State
                                    </Button>
                                </NavLink>
                            </Typography>
                            <br/>
                            

                            <Typography variant="body1">
                                <NavLink 
                                    to={`/scorecard/by_indicator`}
                                    // className="btn btn-outline-dark btn-lg"
                                    >
                                    <Button variant="contained" color="secondary" size="large">
                                        Choose an Indicator
                                    </Button>
                                </NavLink>
                            </Typography>
                        </Paper>  
                        </Grid>
                    </Grid>

                    <Grid item sm={12} md={6}>
                        <OpportunityGapFootnotes/>
                    </Grid>
                    
                </Grid>
            </div>
        )
    }
})

export default Home; 