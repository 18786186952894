import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import {UnitedStatesContext} from '../Contexts/UnitedStatesContext';
import colors from '../Assets/colors'


export default function OpportunityGapFootnotes(){
    const {resources}= useContext(UnitedStatesContext);

    return (
        <div style={{paddingTop: '.5rem', marginTop: '3rem', borderTopColor: colors.medium_gray, borderTopStyle: 'solid'}}>
            <Typography variant="caption">
                <ReactMarkdown
                    linkTarget="_blank"
                    children={`[1] ${resources.filter(r => r.fields.Name === 'total_unemployed_footnote').map(r => r.fields.Language)[0]}`}
                ></ReactMarkdown>
            </Typography>

           <Typography variant="caption">
                <ReactMarkdown
                    linkTarget="_blank"
                    children={`[2] ${resources.filter(r => r.fields.Name === 'unemployed_without_ds_footnote').map(r => r.fields.Language)[0]}`}
                ></ReactMarkdown>
            </Typography>

            <Typography variant="caption">
                <ReactMarkdown
                    linkTarget="_blank"
                    children={`[3] ${resources.filter(r => r.fields.Name === 'jobs_with_ds_footnote').map(r => r.fields.Language)[0]}`}
                ></ReactMarkdown>
            </Typography>
            
            <Typography variant="caption">
                <ReactMarkdown
                    linkTarget="_blank"
                    children={`[4] ${resources.filter(r => r.fields.Name === 'funding_per_unemployed_footnote').map(r => r.fields.Language)[0]}`}
                ></ReactMarkdown>
            </Typography>

        </div>
    )
}