import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import WebfontLoader from '@dr-kobros/react-webfont-loader';

// Importing Bootsrap CSS
// import 'bootstrap/dist/css/bootstrap.min.css';

const config = {
  google: {
    families: ['Montserrat:300', 'Raleway:300'],
  }
};

ReactDOM.render(
  <React.StrictMode>
    <WebfontLoader config={config}>
      <App />
    </WebfontLoader>
  </React.StrictMode>,
  document.getElementById('root')
);
