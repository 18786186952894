import React from 'react';
import colors from '../Assets/colors';

function Strong(props){

    const {yellow, dark_gray} = colors; 

    const style={
        fontWeight: "bold", 
        color: dark_gray, 
        backgroundColor: props.highlight ? yellow : null
    }

    return(
       <span style={style}>{props.children}</span>
    )
}

export default Strong;