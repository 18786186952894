import React, { useState, useEffect } from "react";
import colors from '../Assets/colors';
import * as d3 from 'd3';

var _ = require("lodash");

export default function GenerateLabel(props) {
  let n = props.n;
  let colorArray = props.colorArray;
  let viewBox = `0 0 1 1`;
  const [active, setActive] = useState('');

  useEffect(() => {
        if(props.filterRange.length === 0){
            setActive('');
        }
    }, [props.filterRange])

  function darken(color, k = 3) {
        const {l, c, h} = d3.lch(color);
        return d3.lch(l - 18 * k, c, h);
    }


  return (
    // Horizontal :
    <div>
      <svg
        preserveAspectRatio="xMinYMin"
        width={`${n * 35 * 2}px`}
        height="35px"
        viewBox={viewBox}
      >
        {colorArray.map((c, i) => (
          <g key={`color_${i}`}>
            <rect 
              x={i * 2 + 1} 
              width=".95" 
              height=".5" 
              fill={active === `color_${i}`? colors.NDIA_red : c} 
              cursor='pointer'
              id={`color_${i}`}
              onClick={(event) => {
                setActive(event.target.id);

                props.onChangeFilter( [_.round(props.scores[i], 3), _.round(props.scores[i] + 1, 3)] )
              }}
            />
            <text x={i * 2 + 1} y={0.85} fontSize=".4">
              {i === props.scores.length - 1
                ? _.round(props.scores[i], 3)
                : _.round(props.scores[i], 3) +
                  " - " +
                  _.round(props.scores[i + 1], 3)}
            </text>
          </g>
        ))}
      </svg>
    </div>
  );
}
