import React, { useEffect, useState } from "react";
import colors from '../Assets/colors';
import { makeStyles } from '@material-ui/core/styles';
import StatePath from './StatePath';
import usa from '@svg-maps/usa';
import { useLocation } from "react-router-dom";
// import { UnitedStatesContext } from '../Contexts/UnitedStatesContext';

const _ = require('lodash');

const useStyles = makeStyles({
  root: {
    '& .state': {
      fill: colors.light_gray,
      stroke: 'white',
      strokeWidth: '1',
      opacity: 100,
      '&:hover' : {
        cursor: 'pointer',
        fill: colors.medium_gray
      }
    },
    
    '& .text': {
      color: 'white',
      fill: 'white'
    }
  }
})


export default function USMapImage(props) {
  const classes = useStyles();
  // const { stateData }= useContext(UnitedStatesContext);
  const [activeState, setActiveState] = useState();
  let urlLocation = useLocation();

  const baseURL = urlLocation.pathname.split('/').length > 3 ? urlLocation.pathname.split('/')[0] + '/' + urlLocation.pathname.split('/')[1] + '/' + urlLocation.pathname.split('/')[2] : urlLocation.pathname

  useEffect(() => {
    if(props.value !== null){
      setActiveState(props.value)
    } else {
      setActiveState('')
    }
  }, [props.value])

 
  // const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="487"
      // height="299.992"
      // style={{ position: "relative", left: -0.72398, top: -0.994794 }}
      style={{width: "100%"}}
      overflow="hidden"
      preserveAspectRatio="xMinYMin"
      viewBox={usa.viewBox}
    >
      {usa.locations.map(location => 
        <StatePath 
          key={_.uniqueId(`${props.name}`)}
          id={location.id}
          name={location.name}
          d={location.path}
          activeState={activeState}
          filterRange={props.filterRange}
          strokeLinejoin="round"
          strokeWidth="1.875"
          cursor="pointer" 
          className='state' 
          onStateClick={(state) => {
            setActiveState(state);
            props.onChangeState(state);
            window.history.pushState(`${baseURL}/${state}`, '', `${baseURL}/${state}`);
          }}
          
          />)
      }
      
    </svg>
    </div>
  );
}
