import React, {useState, useContext} from 'react'
import { List, ListItem, ListItemIcon, ListItemText, Typography, Link } from '@material-ui/core';
import { UnitedStatesContext } from '../Contexts/UnitedStatesContext';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Strong from '../Components/Strong';
import colors from '../Assets/colors'
import ReactMarkdown from 'react-markdown';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(5),
    borderLeftStyle: 'black'
  },
}));


export default function FullReport(props){
    const {subindicators, indicators} = useContext(UnitedStatesContext);
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    
    return (
        <div>
        {indicators.length > 1 && indicators.map(indicator => 
                        <div id={`${indicator.fields.Name}_full`} key={`${indicator.fields.Name}_full`}>
                            <Typography variant="h5">
                                <Strong>{indicator.fields.Title}</Strong>
                            </Typography>
                            <Typography variant="body1">
                                <ReactMarkdown
                                    linkTarget="_blank"
                                    children={
                                        props.stateData.fields[`${indicator.fields.Name}_note`]
                                        }
                                ></ReactMarkdown>
                            </Typography>
                        
                            <List className={classes.root}>
                                {indicator.fields['Sub-Indicators'].sort((a,b) => subindicators.filter(subindicator => subindicator.id === a).map(subindicator => subindicator.fields.Order)[0] -  subindicators.filter(subindicator => subindicator.id === b).map(subindicator => subindicator.fields.Order)[0]).map(rec_id => 

                                <div>
                                    <ListItem key={rec_id} alignItems="flex-start">

                                        {/* Indicator Question */}
                                        {subindicators.filter(subindicator => subindicator.id === rec_id).map(subindicator => 
                                        <ListItemText                                   
                                            style={{marginRight: '1.5rem'}}
                                            primary= {subindicator.fields.Question}
                                            secondary={subindicator.fields.Specification}
                                        />)}

                                        {/* Indicator Score --> check or x */}
                                        {props.stateData.fields["Sub-Indicators Met"] ? (props.stateData.fields["Sub-Indicators Met"].includes(rec_id) ?
                                            <ListItemIcon>
                                                <CheckCircleIcon style={{fill: colors.green}}/>
                                            </ListItemIcon>
                                            : <ListItemIcon>
                                                <CancelIcon style={{fill: colors.NDIA_red}}/>
                                            </ListItemIcon>) 
                                            : <ListItemIcon>
                                                <CancelIcon style={{fill: colors.NDIA_red}}/>
                                            </ListItemIcon>}

                                    </ListItem>
                                        <List component="div" disablePadding>
                                            <ListItem className={classes.nested}>
                                                {subindicators.filter(subindicator => subindicator.id === rec_id).map(subindicator => 
                                                <ListItemText 
                                                    key={`${rec_id}_note`}
                                                    style={{paddingLeft:'1rem', borderLeftColor: colors.medium_gray, borderLeftStyle: 'solid'}}
                                                    primary={props.stateData.fields[`${subindicator.fields.Name}_note`]?
                                                    <Typography variant="body1">
                                                    <ReactMarkdown
                                                        linkTarget="_blank"
                                                        children={props.stateData.fields[`${subindicator.fields.Name}_note`]}
                                                    ></ReactMarkdown></Typography> 
                                                :
                                                null
                                                } />)}
                                            </ListItem>
                                        </List>
                                    <div style={{fontSize: ".75rem", paddingLeft: '1rem'}}>
                                        <Link href="#top">
                                            <ArrowUpwardIcon style={{fontSize: ".75rem"}}/>
                                            Back to Top
                                        </Link>
                                    </div>
                                    
                                </div>
                                )}
                                
                            </List>                                
                        </div>
                    )}

        </div>
    )
}

