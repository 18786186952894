import React, { useState, useEffect, useContext } from 'react';

// import IndicatorSummaries from '../Components/IndicatorSummaries';
import Footnote from '../Components/Footnote';
import Strong from '../Components/Strong';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography, Link } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import OpportunityGapFootnotes from '../Components/OpportunityGapFootnotes';



import {UnitedStatesContext} from '../Contexts/UnitedStatesContext';

export default function NatOpportunityGap(props){

    const {loading, resources, stateData}= useContext(UnitedStatesContext);

    function sum(field){

        if(stateData){
            const count = stateData.reduce( (a, b) => {
                return a + (b.fields[field] || 0);
                }, 0)

            return (
                Math.round(count)
            )  
        }
                    
    }

    function sumMoneyPer(numerator, denominator){

        if(stateData){
            const numerator_sum = stateData.reduce( (a, b) => {
                return a + (b.fields[numerator] || 0);
            }, 0)

            const denominator_sum = stateData.reduce( (a, b) => {
                return a + (b.fields[denominator] || 0)
            }, 0)

            const money_per = numerator_sum / denominator_sum;

            return (
                money_per.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
            )
        }
    }

    const sum_total_unemployed = sum('total_unemployed')
    const sum_unemployed_without_ds = sum('unemployed_without_ds');
    const sum_jobs_with_ds = sum('jobs_with_ds');
    const percent_jobs_with_ds = sum('jobs_with_ds') / sum('jobs_all');
    const percent_unemployed_reached = (sum('funding') / 4919) / sum('total_unemployed');
    let text = '';

    if(resources.length >= 1){
        text = resources.filter(item => item.fields['Name'] === 'opportunity_gap').map(item => item.fields['Language'])[0]
            .replaceAll('[region]', 'the United States')
            .replace('[totalUnemployed]', `${sum_total_unemployed.toLocaleString('en')}`)
            .replace('[unemployedWithoutDS]', `${sum_unemployed_without_ds.toLocaleString('en')}`)
            .replace('[jobsWithDS]', `${sum_jobs_with_ds.toLocaleString('en')}`)
            .replace('[jobsPercent]', `${percent_jobs_with_ds.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1})}`)
            .replace('[percentUnemployedServed]', `${percent_unemployed_reached.toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1})}`)
    }
    
    
    return (
        <div>
        {loading ? <CircularProgress/> : 
            <div>

                    {resources.length >= 1 && <ReactMarkdown
                        children={text}
                    >
                    </ReactMarkdown>
                    }

            </div>
        }
        </div>
    )
}

