import React, {useState, useEffect, useContext} from 'react';

export const UnitedStatesContext = React.createContext();

export const UnitedStatesProvider = props => {
    
    const [stateData, setStateData] = useState([]);
    const [subindicators, setSubindicators] = useState([]);
    const [indicators, setIndicators] = useState([]);
    const [definitions, setDefinitions] = useState([]);
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        fetch(`/api/stateData`)
        .then(res => res.json())
        .then(data => {
            console.log(data)
            setStateData(data)
        })
        
        fetch(`/api/subindicators`)
        .then(res => res.json())
        .then(data => {
            console.log(data)
            setSubindicators(data)
        });

        fetch(`/api/indicators`)
        .then(res => res.json())
        .then(data => {
            console.log(data)
            setIndicators(data)
        });

        fetch(`/api/definitions`)
        .then(res => res.json())
        .then(data => {
            console.log(data)
            setDefinitions(data)
        });

        fetch(`/api/resources`)
        .then(res => res.json())
        .then(data => {
            console.log(data)
            setResources(data)
        });

        setLoading( (stateData !== {} && indicators !== {}) && false )
    }, []);

    return (
        <UnitedStatesContext.Provider value= {{ stateData, subindicators, indicators, definitions, resources, loading}}>
            {props.children}
        </UnitedStatesContext.Provider>
    )
}