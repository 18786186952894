import React, {useState, useContext, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import { UnitedStatesContext } from '../Contexts/UnitedStatesContext';
import colors from '../Assets/colors';
import Strong from '../Components/Strong';
import usa from '@svg-maps/usa';
import { makeStyles, styled } from '@material-ui/core/styles';
import { TextField, List, ListItem, ListItemIcon, ListItemText, GridList, GridListTile, GridListTileBar, Paper, Typography, Link, ButtonGroup, Container } from '@material-ui/core';
import MyPaper from '../Components/MyPaper';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import statesTitlecase from '../Assets/statesTitlecase';
import ReactMarkdown from 'react-markdown';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  useRouteMatch,
  useParams
} from "react-router-dom";
import * as d3 from 'd3';
import { active } from 'd3';


const _ = require('lodash');

const useStyles = makeStyles({
  root: {
    '& .state': {
      // fill: 'rgb(3, 78, 123)',
      fill: colors.pink,
      stroke: 'white',
      strokeWidth: '2px',
      opacity: 100
    },
    '& .emerging-state': {
      backgroundColor: colors.NDIA_red, 
      margin: '1rem', 
      color: 'white', 
      padding: '6px 16px', 
      fontSize: '0.875rem', 
      minWidth: '64px', 
      boxSizing: 'border-box', 
      fontWeight: '500', 
      lineHeight: '1.75',    
      borderRadius: '4px', 
      textTransform: 'uppercase', 
      '&:hover': 
        {
          textDecoration: 'none', 
          color: 'black',
          backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    },
    '& .indicator-button': {
      backgroundColor: 'white',
      color: colors.NDIA_teal,
      '&:hover':
      {
        backgroundColor: colors.NDIA_teal,
        color: 'white'
      }
    }
  },
  gridContent: {
      margin: '3rem',
      padding: '1.5rem'
  },
  
})

function ComboBox(props) {
  return (
    <Autocomplete
        id="combo-box-demo"
        options={props.data}
        getOptionLabel={(option) => option.fields["Title"]}
        style={{ width: 500 }}
        renderInput={(params) => <TextField {...params} label="Select a Category" variant="standard" />
        }
        onChange={(event, newValue) => props.onSelectIndicator(newValue)}   
    />
  );
}

export default function ByIndicator(){
    const {stateData, subindicators, indicators}= useContext(UnitedStatesContext);
    const [activeIndicator, setActiveIndicator] = useState(indicators[0]);
    const classes = useStyles();
    
    let match = useRouteMatch();

    function stateStyle(stateId){
      let colorArray = [];
      let thisStateSubindicators = []
      let indicatorScore = 0;
      let n = activeIndicator.fields["Number of Sub-Indicators"] + 1

      if(activeIndicator){
        colorArray = d3.quantize(d3.interpolateRgb(colors.NDIA_light_teal, colors.NDIA_dark_teal), n);

        // OLD : interpolateLab

        thisStateSubindicators = stateData.filter(state => state.fields["State Abbreviation "] === stateId).map(state => state.fields["Sub-Indicators Met"])[0]

        if(thisStateSubindicators){
           activeIndicator.fields['Sub-Indicators'].forEach(rec_id => 
              thisStateSubindicators.forEach(other_rec => 
                {
                  if(rec_id === other_rec){
                    indicatorScore ++
                  }
                }
              )
          )
        }        
      }

      let random = Math.floor(Math.random() * n)

      return {cursor: 'pointer', fill: colorArray[indicatorScore]} 
      // return {fill: activeCategory ? colorArray[random] : colors.light_gray} 

    }

    function GenerateLabel(){
      let n = activeIndicator.fields["Number of Sub-Indicators"] + 1
      let colorArray = d3.quantize(d3.interpolateLab(colors.NDIA_light_teal, colors.NDIA_dark_teal), n);
      let viewBox = `0 0 1 1`
      return (
        // <div>
        //   <svg
        //     preserveAspectRatio="xMinYMin"
        //     width= {`${n*50}px`}
        //     height= '50px'
        //     viewBox={viewBox}
        //   >
        //     {colorArray.map((c, i) => (
        //       <g key={`color_${i}`}>
        //         <rect x={i} width='.5' height='.5' fill={c}/>
        //         <text x={i+.2} y={.75} font-size=".2">{i}</text>
        //       </g>
        //     ))}
        //   </svg>
        // </div>

        // Horizontal : 
        <div>
          <svg
            preserveAspectRatio="xMinYMin"
            width= {`${n*30}px`}
            height= '30px'
            viewBox={viewBox}
          >
            {colorArray.map((c, i) => (
              <g key={`color_${i}`}>
                <rect x={i} width='.75' height='.5' fill={c}/>
                <text x={i+.2} y={.85} fontSize=".4">{i}</text>
              </g>
            ))}
          </svg>
        </div>
        
        // <Grid container direction="column" justify="center" alignItems="center">
        //   <Typograpghy variant="h5">Indicators met from 0 - {n-1}</Typograpghy >
        //   <GridList  justify="center" alignItems="center"cellHeight={50} cols={n}>
        //     {colorArray.map((c, i) => (
        //       <GridListTile key={c}>
        //         <StopIcon style={{ fontSize: 50, color: c }} />
        //       </GridListTile>
        //     ))}
        //   </GridList>
        // </Grid>
      )
    }

    return(
        <div className={classes.root}>
            <Grid container
                direction="row"
                justify="space-evenly"
                alignItems="flex-start"
                // spacing={4}
                >
                <Grid 
                    item
                    sm={12}
                    md={2}>
                    {/* <h3>Scorecard Categories</h3> */}
                    <div style={{marginLeft: '1.5rem', marginTop: '1.5rem'}}>
                      {/* <ComboBox 
                        data={categories}
                        onSelectIndicator={(selected) => {
                          setActiveCategory(selected);
                          }}
                      />  */}
                      <ButtonGroup
                        size="large"
                        disableElevation
                        orientation="vertical"
                        color="primary"
                        aria-label="vertical contained primary button group"
                        // variant="contained"
                      >
                        
                        {activeIndicator && indicators.map(indicator => 
                          <Button 
                            className='indicator-button'
                            id={indicator.fields["Title"]}
                            style={
                              activeIndicator.fields['Name'] === indicator.fields["Name"] ? 
                              {backgroundColor: colors.NDIA_teal, color: 'white'} : {backgroundColor: 'white', color: colors.NDIA_teal}
                              }
                            onClick={(event) => setActiveIndicator(indicators.filter(ind => ind.fields['Title'] === indicator.fields["Title"])[0])}
                            >
                              {indicator.fields["Title"]}
                          </Button>)}

                      </ButtonGroup>
                    </div>
                    </Grid>

                    <Grid 
                      item
                      sm={12}
                      md={5}>
                    {activeIndicator && 
                      <div>
                        <MyPaper elevation={2}>
                          <Typography variant="body1"> {activeIndicator.fields['Description']} </Typography>
                          <br/>
                          <Typography variant="body1"><Strong>Sub-Indicators:</Strong> </Typography>
                          <List>
                            {activeIndicator.fields['Sub-Indicators'].sort((a,b) => subindicators.filter(subindicator => subindicator.id === a).map(subindicator => subindicator.fields.Order)[0] -  subindicators.filter(subindicator => subindicator.id === b).map(subindicator => subindicator.fields.Order)[0]).map(rec_id => 
                              <ListItem>
                                <ListItemText
                                  primary={subindicators.filter(subindicator => subindicator.id === rec_id).map(subindicator => subindicator.fields.Question)}
                                  secondary={subindicators.filter(subindicator => subindicator.id === rec_id).map(subindicator => subindicator.fields.Specification ? subindicator.fields.Specification : "")}
                                />
                              </ListItem>
                            )}
                          </List>
                          {activeIndicator.fields["Best Emerging Practices"] && 
                          <div>
                            <Typography variant="body1" style={{marginBottom: '1rem'}}> <Strong>Best Emerging Practices: </Strong></Typography>
                            <Container style={{display: 'flex', flexWrap: 'wrap'}}>
                              <Route>
                                {activeIndicator.fields["Best Emerging Practices"]
                                .map(rec_id => 
                                  <NavLink
                                    key={`link_to_${rec_id}`}
                                    className="emerging-state"
                                    to={`/scorecard/by_state/${stateData.filter(state => state.id === rec_id).map(state => state.fields['State Abbreviation '])}/${activeIndicator.fields['Name']}_full`}
                                  > 
                                    {stateData.filter(state => state.id === rec_id).map(state => state.fields["State Name"])}
                                  </NavLink>)}
                                </Route>
                            </Container>
                          </div>
                          }
                        </MyPaper>
                      </div>
                     
                    }
                </Grid>

                <Grid 
                  item 
                  sm={12}
                  md={5}
                  // container
                  // direction="column"
                  // justify="flex-start"
                  // alignItems="center"
                  >
                  
                  <div
                    style={{marginRight: '1.5rem'}}
                  >                  
                    <div 
                      style={{
                          alignItems: 'flex-end',
                          alignContent: 'flex-end',
                          justifyItems: 'left',
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          flexWrap: 'wrap'
                      }}
                    >
                      { 
                        activeIndicator && <Typography variant="body1">Sub-Indicators Met</Typography >
                      }
                      {
                        activeIndicator && <GenerateLabel/>
                      }
                    </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        // width="487"
                        // height="299.992"
                        // style={{ position: "relative", left: -0.72398, top: -0.994794 }}
                        style={{width: "100%"}}
                        overflow="hidden"
                        preserveAspectRatio="xMinYMin"
                        viewBox={usa.viewBox}
                        >
                        {usa.locations.map(location => <path 
                            id={location.id}
                            key={location.id}
                            name={location.name}
                            d={location.path}
                            className='state'
                            onClick={(event) => {window.location.href = `/scorecard/by_state/${_.upperCase(location.id)}`}}
                            cursor='pointer'
                            style={
                              activeIndicator && stateStyle(_.upperCase(location.id))
                            }
                            strokeLinejoin="round"
                            strokeWidth="1.875" ></path>)}
                        </svg>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
